<template>
  <div class="container">
    <video class="background-video" autoplay loop muted>
      <source src="@/assets/video/background.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>

    <div class="overlay"></div>

    <div class="timer-content">

      <div class="logo" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
        <a href="#"><img alt="" src="@/assets/logo-light.png" /></a>
      </div>

      <div class="time" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="750">
        <span class="time-unit">{{ days }}<span class="label">dni</span></span> 
        <span class="separator">:</span>
        <span class="time-unit">{{ hours }}<span class="label">godzin</span></span>
        <span class="separator">:</span>
        <span class="time-unit">{{ minutes }}<span class="label">minut</span></span>
        <span class="separator">:</span>          
        <span class="time-unit">{{ seconds }}<span class="label">sekund</span></span>
      </div>

      <h1 data-aos="fade-up" data-aos-duration="1500" data-aos-delay="1400"><a>#</a>MakeArmaGreatAgain</h1>

      <div class="button-container" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="2100">

        <a href="https://discord.gg/DAgR3c5D8V" target="_blank" rel="noopener noreferrer" class="button" >
          <div class="icon-container">
            <img src="@/assets/arma.png" alt="" class="button-icon">
          </div> 
          Discord Arma 
        </a>

        <a href="https://steamcommunity.com/profiles/76561198214108812/myworkshopfiles/" target="_blank" rel="noopener noreferrer" class="button" @mouseenter="checkDisplayOnHover">
          Sprawdź Warsztat
        </a>

        <a href="https://steamcommunity.com/id/765611982141088122/myworkshopfiles/" target="_blank" rel="noopener noreferrer" class="button">
          <div class="icon-container">
            <img src="@/assets/fivem.png" alt="" class="button-icon">
          </div>
          Discord FiveM
        </a>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      targetDate: new Date("2025-02-12T00:00:00"),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      intervalId: null    
    };
  },
  methods: {
    calculateTimeRemaining() {
      const now = new Date();
      const distance = this.targetDate - now;

      if (distance > 0) {
        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      } else {
        clearInterval(this.intervalId);
        this.days = this.hours = this.minutes = this.seconds = 0;
      }
    },
  },
  mounted() {
    this.calculateTimeRemaining();
    this.intervalId = setInterval(this.calculateTimeRemaining, 1000);
  },
};
</script>

<style>

:root {
  --shadowBlack: rgba(0, 0, 0, 0.7);
  --discordPurpleHover: rgba(72, 91, 208, 1.0);
  --discordPurple: rgba(88, 101, 242, 1.0);
  --lakesideBlue: rgba(75, 156, 181, 1.0);
}

.container {
  position: relative;
  width: 100%;height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.3));
  z-index: 0;
}

.background-video {
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  object-fit: cover;
  z-index: -1;
}

.logo img {
  width: 250px; height: auto; 
  margin: 0 0;
}

.timer-content {
  position: relative;
  z-index: 1;
  text-align: center;
  font-family: var(--body-font);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.timer-content h1 {
  font-size: 2.75rem;
  text-shadow: 2px 2px 10px var(--shadowBlack);
  margin: 15px 0;
}

.timer-content h1 a {
  color: var(--lakesideBlue);
}

.time {
  display: flex;
  gap: 10px;
  font-size: 3.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 10px var(--shadowBlack);
  margin: 15px 0;
}

.time-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.separator {
  font-size: 3.5rem;
}

.label {
  font-size: 1rem;
  font-weight: normal;
  margin-top: 0.2em;
  text-shadow: 2px 2px 10px var(--shadowBlack);
}

.button-container {
  display: flex;
  margin: 20px 0;
  justify-content: center;
  padding: 10px;
  overflow: visible;
  gap: 15px;
}

.button {
  position: relative;
  padding: 0.8em 1.8em;
  font-size: 1.0rem;
  background-color: var(--lakesideBlue);
  border: none;
  border-radius: 8px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  box-shadow: 2px 2px 12px var(--shadowBlack);
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease, padding 0.5s ease;
  white-space: nowrap;
}

.button:first-child {
  transform: skewX(-8deg);
}

.button:last-child {
  transform: skewX(8deg);
}

.button:hover {
  background-color: var(--discordPurpleHover);
  box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.4);
  transform: translateY(-3px);
}

.icon-container {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;
  opacity: 0;
}

.button:first-child .icon-container {
  left: -40px;
}

.button:last-child .icon-container {
  right: -40px;
}

.button:first-child:hover .icon-container {
  left: 10px;
  opacity: 1;
}

.button:last-child:hover .icon-container {
  right: 10px;
  opacity: 1;
}

.button:nth-child(2) .icon-container {
  right: 40px;
}

.button-icon {
  width: 35px;
  height: auto;
  padding: 5px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .timer-content h1 {
    font-size: 2rem;
  }

  .time {
    font-size: 2.5rem;
  }

  .button-container {
    flex-direction: column;
    gap: 10px;
  }

  .timer-content h1 {
    font-size: 1.8rem;
  }

  .button {
    padding: 0.6em 1.4em;
    font-size: 1rem;
  }

  .button:first-child, .button:last-child{
    transform: none;
    transform: translateY(0px);
  }

  .button:first-child:hover, .button:last-child:hover {
    transform: scale(1.05);
  }

  .icon-container, .separator {
    display: none;
  }
}

@media (min-width: 768px) {
  .button:first-child:hover { 
    padding-left: 60px;
  }

  .button:last-child:hover {
    padding-right: 60px;
  }
}

</style>
